export const itemBuilder = {
  flights: {
    departureDate: '',
    departureTime: '',
    departureAirport: '',
    departureCity: '',
    arrivalDate: '',
    arrivalTime: '',
    arrivalAirport: '',
    arrivalCity: '',
  },
  hotels: {
    checkInDate: '',
    checkOutDate: '',
    hotelName: '',
    hotelAddress: '',
    hotelCity: '',
  },
  cars: {
    pickUpDate: '',
    pickUpTime: '',
    pickUpLocation: '',
    returnDate: '',
    returnTime: '',
    returnLocation: '',
    carMakeModelYear: '',
  },
  activities: {
    name: '',
    location: '',
    date: '',
    time: '',
    notes: '',
  },
  checklist: {
    name: '',
    checked: false,
  }
}